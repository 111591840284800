import './App.css'

import * as React from "react";
import { useState, useEffect } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import IconButton from '@mui/material/IconButton';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import QRCode from 'qrcode';

// Amplify libraries
import Amplify, { Storage } from "aws-amplify";
import { AmplifyAuthContainer, AmplifyAuthenticator, AmplifySignIn, AmplifySignOut } from "@aws-amplify/ui-react";
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import awsconfig from "./aws-exports";
Amplify.configure(awsconfig);


const App = () => {
  const [uid] = useState(Math.random().toString(36).slice(2));

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [organizationName, setOrganizationName] = useState("");
  const [contactUID, setContactUID] = useState("");

  const [userAvatarUrl, setUserAvatarUrl] = useState("");
  const [userAvatarData, setUserAvatarData] = useState("");
  const [userAvatarUploading, setUserAvatarUploading] = useState(false);
  const [organizationLogoUrl, setOrganizationLogoUrl] = useState("");
  const [organizationLogoUploading, setOrganizationLogoUploading] = useState(false);

  const [rev, setRev] = useState("");

  const [vcfFileUrl, setVcfFileUrl] = useState("");
  const vcfFileBaseUrl = process.env.REACT_APP_VCF_FILE_BASE_URL;

  const [authState, setAuthState] = useState<AuthState>();
  const [user, setUser] = useState<object | undefined>();

  const [qrCodeData, setQRCodeData] = useState("");

  useEffect(() => {
      return onAuthUIStateChange((nextAuthState, authData) => {
          setAuthState(nextAuthState);
          setUser(authData);
      });
  }, []);

  const toBase64 = (file: Blob) => {
    return new Promise(resolve => {
      var reader = new FileReader();
      reader.onload = function(event) {
        if (event && event.target) {
          resolve(event.target.result);
        }
      };
      
      reader.readAsDataURL(file);
    });
  };

  const handleUserAvatarChange = async (e: any) => {
    const file = e.target.files[0];
    await toBase64(file).then(result => setUserAvatarData(("" + result).split("base64,")[1]));
    try {
      const filePath = "images/" + uid + "/avatar.jpg";
      setUserAvatarUploading(true);
      await Storage.put(filePath, file);
      const url = await Storage.get(filePath);
      setUserAvatarUrl(url);
      setUserAvatarUploading(false);
    } catch (err) {
      console.debug(err);
    }
  };

  const handleOrganizationLogoChange = async (e: any) => {
    const file = e.target.files[0];
    try {
      const filePath = "images/" + uid + "/org-logo.jpg";
      setOrganizationLogoUploading(true);
      await Storage.put(filePath, file);
      const url = await Storage.get(filePath);
      setOrganizationLogoUrl(url);
      setOrganizationLogoUploading(false);
    } catch (err) {
      console.debug(err);
    }
  };

  function Copyright(props: any) {
    return (
      <Typography
        variant="body2"
        color="text.secondary"
        align="center"
        {...props}
      >
        {"Copyright © "}
        <Link color="inherit" href="/">
          vCardCampus
        </Link>{" "}
        {new Date().getFullYear()}
        {"."}
      </Typography>
    );
  }

  const theme = createTheme({
    palette: {
      primary: {
        light: "#757ce8",
        main: "#183052",
        dark: "#002884",
        contrastText: "#fff",
      },
      secondary: {
        light: "#ff7961",
        main: "#f44336",
        dark: "#ba000d",
        contrastText: "#000",
      },
    },
  });

  const handleFirstNameChange = (event: any) => {
    setFirstName(event.target.value);
    updateContactUID(("" + (organizationName ? (organizationName + "-") : "") + (firstName ? (firstName + "-") : "") + (lastName ? (lastName) : "")).toLowerCase().replace(/ /g, '').trim());
  };

  const handleLastNameChange = (event: any) => {
    setLastName(event.target.value);
    updateContactUID(("" + (organizationName ? (organizationName + "-") : "") + (firstName ? (firstName + "-") : "") + (lastName ? (lastName) : "")).toLowerCase().replace(/ /g, '').trim());
  };

  const handleOrganizationNameChange = (event: any) => {
    setOrganizationName(event.target.value);
    updateContactUID(("" + (organizationName ? (organizationName + "-") : "") + (firstName ? (firstName + "-") : "") + (lastName ? (lastName) : "")).toLowerCase().replace(/ /g, '').trim());
  };

  const handleContactUIDChange = (event: any) => {
    updateContactUID(("" + event.target.value).toLowerCase().replace(/ /g, '').trim());
  };

  const updateContactUID = (cUID: string) => {
    setContactUID(cUID.normalize("NFD").replace(/\p{Diacritic}/gu, ""));
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const data = new FormData(event.currentTarget);
    const mPhone = data.get("mPhone");
    const email = data.get("email");
    const title = data.get("title");
    const wPhone = data.get("wPhone");
    const wEmail = data.get("wEmail");
    const wUrl = data.get("wUrl");
    const addressLine1 = data.get("addressLine1");
    const addressLine2 = data.get("addressLine2") ? data.get("addressLine2") : "";
    const city = data.get("city");
    const county = data.get("county") ? data.get("county") : "";
    const postalCode = data.get("postalCode");
    const country = data.get("country");
    const linkedInUrl = data.get("linkedInUrl");
    const facebookUrl = data.get("facebookUrl");
    const instagramUrl = data.get("instagramUrl");
    const youtubeUrl = data.get("youtubeUrl");
    const whatsAppUrl = data.get("whatsAppUrl");
    const zaloUrl = data.get("zaloUrl");
    const notes = data.get("notes");
    setRev(new Date().toISOString());

    const contactData = {
      uid: "vcardcampus-" + uid,
      contactUID: contactUID,
      first_name: firstName,
      last_name: lastName,
      full_name: firstName + " " + lastName,
      email: email,
      mobile_phone: mPhone,
      user_avatar_data: userAvatarData,
      organization: organizationName,
      job_title: title,
      work_url: wUrl,
      work_email: wEmail,
      work_phone: wPhone,
      address_line_1: addressLine1,
      address_line_2: addressLine2,
      city: city,
      county: county,
      postal_code: postalCode,
      country: country,
      linkedin_url: linkedInUrl,
      facebook_url: facebookUrl,
      instagram_url: instagramUrl,
      youtube_url: youtubeUrl,
      whats_app_url: whatsAppUrl,
      zalo_url: zaloUrl,
      notes: notes,
      rev: rev
    };
    
    const vcfTemplate = // eslint-disable-next-line
      `BEGIN:VCARD` + "\n" + // eslint-disable-next-line
      `VERSION:3.0` + "\n" + // eslint-disable-next-line
      `FN;CHARSET=UTF-8:${firstName} ${lastName}` + "\n" + // eslint-disable-next-line
      `N;CHARSET=UTF-8:${lastName};${firstName};;;` + "\n" + // eslint-disable-next-line
      `UID;CHARSET=UTF-8:vcardcampus-${uid}` + "\n" + // eslint-disable-next-line
      `EMAIL;CHARSET=UTF-8;type=Personal,INTERNET:${email}` + "\n" + // eslint-disable-next-line
      `EMAIL;CHARSET=UTF-8;type=Business,INTERNET:${wEmail}` + "\n" + // eslint-disable-next-line
      `PHOTO;ENCODING=b;TYPE=JPEG:${userAvatarData}` + "\n" + // eslint-disable-next-line
      `TEL;TYPE=Personal:${mPhone}` + "\n" + // eslint-disable-next-line
      `TEL;TYPE=Business,VOICE:${wPhone}` + "\n" + // eslint-disable-next-line
      `ADR;CHARSET=UTF-8;TYPE=Business Address:;;${addressLine1} ${addressLine2};${city};${county};${postalCode};${country}` + "\n" + // eslint-disable-next-line
      `TITLE;CHARSET=UTF-8:${title}` + "\n" + // eslint-disable-next-line
      `ORG;CHARSET=UTF-8:${organizationName}` + "\n" + // eslint-disable-next-line
      `URL;type=Website;CHARSET=UTF-8:${wUrl}` + "\n" + // eslint-disable-next-line
      (linkedInUrl && ("" + linkedInUrl).trim().length > 0 ? (`URL;TYPE=LinkedIn:${linkedInUrl}` + "\n") : "") + // eslint-disable-next-line
      (facebookUrl && ("" + facebookUrl).trim().length > 0 ? (`URL;TYPE=Facebook:${facebookUrl}` + "\n") : "") + // eslint-disable-next-line
      (instagramUrl && ("" + instagramUrl).trim().length > 0 ? (`URL;TYPE=Instagram:${instagramUrl}` + "\n") : "") + // eslint-disable-next-line
      (youtubeUrl && ("" + youtubeUrl).trim().length > 0 ? (`URL;TYPE=Youtube:${youtubeUrl}` + "\n") : "") + // eslint-disable-next-line
      (whatsAppUrl && ("" + whatsAppUrl).trim().length > 0 ? (`URL;TYPE=WhatsApp:${whatsAppUrl}` + "\n") : "") + // eslint-disable-next-line
      (zaloUrl && ("" + zaloUrl).trim().length > 0 ? (`URL;TYPE=Zalo:${zaloUrl}` + "\n") : "") + // eslint-disable-next-line
      `X-SOCIALPROFILE;TYPE=vCardCampus:${contactUID}` + "\n" + // eslint-disable-next-line
      `NOTE;CHARSET=UTF-8:${notes}` + "\n" + // eslint-disable-next-line
      `REV:${rev}` + "\n" + // eslint-disable-next-line
      `END:VCARD`;

    const vcfFilePath = "data/" + contactUID + "/primary.vcf";
    await Storage.put(vcfFilePath, vcfTemplate);
    setVcfFileUrl(vcfFileBaseUrl + vcfFilePath);
    QRCode.toDataURL(vcfFileBaseUrl + vcfFilePath, { errorCorrectionLevel: 'M' }, function (err, url) { setQRCodeData(url); });

    await Storage.put("data/" + contactUID + "/primary.json", JSON.stringify(contactData, null, 2));
    await Storage.put("data/" + contactUID + "/backup-" + Math.random().toString(36).slice(6) + ".json", JSON.stringify(contactData, null, 2));

    if (userAvatarUrl) {
      await Storage.copy({key : "images/" + uid + "/avatar.jpg"}, {key : "data/" + contactUID + "/avatar.jpg"});
    }
    if (organizationLogoUrl) {
      await Storage.copy({key : "images/" + uid + "/org-logo.jpg"}, {key : "data/" + contactUID + "/org-logo.jpg"});
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <AmplifyAuthContainer>
            <AmplifyAuthenticator>
                <AmplifySignIn slot="sign-in" headerText="Sign in vCardCampus App" hideSignUp></AmplifySignIn>
            </AmplifyAuthenticator>
          </AmplifyAuthContainer>

          {authState === AuthState.SignedIn && user && 
          <>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} sx={{ mb: 20 }}>
              </Grid>
              <Grid item xs={12} sm={6}>
                <AmplifySignOut buttonText="Logout"/>
              </Grid>
            </Grid>

            <Avatar src="user.jpg"></Avatar>
            <Typography component="h1" variant="h5">
              Contact
            </Typography>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 5 }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField 
                    autoFocus
                    required
                    fullWidth
                    label="First Name"
                    id="firstName"
                    name="firstName"
                    autoComplete="given-name" 
                    value={firstName} 
                    onChange={handleFirstNameChange} 
                    onKeyDown={handleFirstNameChange} 
                    onBlur={handleFirstNameChange} 
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    label="Last Name"
                    id="lastName"
                    name="lastName"
                    autoComplete="family-name" 
                    value={lastName} 
                    onChange={handleLastNameChange} 
                    onKeyDown={handleLastNameChange} 
                    onBlur={handleLastNameChange} 
                  />
                </Grid>

                <Grid item xs={12}>
                  <Divider style={{ width: "100%" }}></Divider>
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    label="Mobile Phone"
                    id="mPhone"
                    name="mPhone"
                    autoComplete="tel"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Email"
                    id="email"
                    name="email"
                    autoComplete="email"
                  />
                </Grid>

                <Grid item xs={12}>
                  <Divider style={{ width: "100%" }}></Divider>
                </Grid>
                <div style={{ width: "100%", textAlign: "center" }}>
                  <h3> Organization </h3>
                </div>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Organization"
                    id="organization"
                    name="organization"
                    autoComplete="organization" 
                    value={organizationName} 
                    onChange={handleOrganizationNameChange} 
                    onKeyDown={handleOrganizationNameChange} 
                    onBlur={handleOrganizationNameChange} 
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Job Title"
                    id="title"
                    name="title"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Work Phone"
                    id="wPhone"
                    name="wPhone"
                    autoComplete="tel"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Work Email"
                    id="wEmail"
                    name="wEmail"
                    autoComplete="email"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Website"
                    id="wUrl"
                    name="wUrl"
                    autoComplete="url"
                  />
                </Grid>
                <Grid item xs={12} sx={{ ml: 10, mr: 10 }}>
                  <Divider style={{ width: "100%" }}></Divider>
                </Grid>
                <div style={{ width: "100%", textAlign: "center" }}>
                  <h3> Business Address </h3>
                </div>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Address Line 1"
                    id="addressLine1"
                    name="addressLine1"
                    autoComplete="address-line1"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Address Line 2"
                    id="addressLine2"
                    name="addressLine2"
                    autoComplete="address-line2"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Town/City"
                    id="city"
                    name="city"
                    autoComplete="address-level2"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="County"
                    id="county"
                    name="county"
                    autoComplete="address-level1"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Postcode"
                    id="postalCode"
                    name="postalCode"
                    autoComplete="postal-code"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Country"
                    id="country"
                    name="country"
                    autoComplete="country-name"
                  />
                </Grid>

                <Grid item xs={12}>
                  <Divider style={{ width: "100%" }}></Divider>
                </Grid>
                <div style={{ width: "100%", textAlign: "center" }}>
                  <h3> Social Network </h3>
                </div>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="LinkedIn"
                    id="linkedInUrl"
                    name="linkedInUrl"
                    autoComplete="url"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Facebook"
                    id="facebookUrl"
                    name="facebookUrl"
                    autoComplete="url"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Instagram"
                    id="instagramUrl"
                    name="instagramUrl"
                    autoComplete="url"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Youtube"
                    id="youtubeUrl"
                    name="youtubeUrl"
                    autoComplete="url"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="WhatsApp"
                    id="whatsAppUrl"
                    name="whatsAppUrl"
                    autoComplete="url"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Zalo"
                    id="zaloUrl"
                    name="zaloUrl"
                    autoComplete="url"
                  />
                </Grid>

                <Grid item xs={12}>
                  <Divider style={{ width: "100%" }}></Divider>
                </Grid>

                <div style={{ width: "100%", textAlign: "center" }}>
                  <h3> Personal Avatar </h3>
                  {userAvatarUploading ? (
                    <h4>Uploading...</h4>
                  ) : (
                    <input
                      type="file"
                      accept="image/jpg"
                      onChange={(evt) => handleUserAvatarChange(evt)} 
                    />
                  )}
                  <div>
                    {userAvatarUrl ? (
                      <img style={{ width: "20rem" }} src={userAvatarUrl} alt="avatar"/>
                    ) : (
                      <span />
                    )}
                  </div>
                </div>
                <Grid item xs={12} sx={{ ml: 10, mr: 10 }}>
                  <Divider style={{ width: "100%" }}></Divider>
                </Grid>
                <div style={{ width: "100%", textAlign: "center" }}>
                  <h3> Organization Logo </h3>
                  {organizationLogoUploading ? (
                    <h4>Uploading...</h4>
                  ) : (
                    <input
                      type="file"
                      accept="image/jpg"
                      onChange={(evt) => handleOrganizationLogoChange(evt)}
                    />
                  )}
                  <div>
                    {organizationLogoUrl ? (
                      <img style={{ width: "20rem" }} src={organizationLogoUrl} alt="organization logo"/>
                    ) : (
                      <span />
                    )}
                  </div>
                </div>
                <Grid item xs={12} sx={{ ml: 10, mr: 10 }}>
                  <Divider style={{ width: "100%" }}></Divider>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    multiline
                    minRows="6"
                    label="Notes"
                    id="notes"
                    name="notes"
                  />
                </Grid>

                <Grid item xs={12}>
                  <Divider style={{ width: "100%" }}></Divider>
                </Grid>

                <Grid item xs={12} sx={{ mt: 1, mb: 5 }}>
                  <TextField
                    required
                    fullWidth
                    label="Contact Unique ID"
                    id="cuid"
                    name="cuid" 
                    value={contactUID} 
                    onChange={handleContactUIDChange} 
                    onKeyDown={handleContactUIDChange} 
                    onBlur={handleContactUIDChange} 
                  />
                </Grid>
              </Grid>


              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }} 
                style={{ minHeight: '64px' }} 
                className="btn" 
              >
                GENERATE
              </Button>


              <Grid container spacing={2} sx={{ mt: 2, mb: 8 }}>
                <div style={{ width: "100%", textAlign: "center" }}>
                  { rev ? (<h5> Rev. {rev} </h5>) : ''}
                </div>
                <Grid item xs={10} >
                  <TextField
                    fullWidth
                    label="vCard URL"
                    id="vcfFileUrl"
                    name="vcfFileUrl" 
                    value={vcfFileUrl} 
                    contentEditable={false} 
                    suppressContentEditableWarning={true} 
                    style={{ backgroundColor: "#e8ca76" }}
                  />
                </Grid>
                <Grid item xs={2} >
                  <CopyToClipboard text={vcfFileUrl}>
                    <IconButton aria-label="copy" size="large" title="Copy to clipboard">
                      <ContentCopyIcon fontSize="inherit" style={{ transform: "scaleX(-1)" }}/>
                    </IconButton>
                  </CopyToClipboard>
                </Grid>
                <Grid item xs={12} >
                  <div style={{ width: "100%", textAlign: "center" }}>
                    { qrCodeData ? (<img src={qrCodeData} alt="QR Code" style={{ width: "320px" }}/>) : ''}
                  </div>
                </Grid>
              </Grid>
            </Box>
          </>
          }
        </Box>
        <Copyright sx={{ mt: 5 }} />
      </Container>
    </ThemeProvider>
  );
};

export default App;
